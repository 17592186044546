.newProduct {
    width:90%;
    margin-left: 5%;
    margin-right: 5%;
    position:absolute;
    height: 70%;
    overflow: hidden;
    overflow-y: scroll;
    top:20%;
    bottom:10%;
    margin-bottom: 5rem;
    
  }
  
  .addProductForm {
    margin-top: 10px;
    display:grid;
    align-items: center;
    justify-items: center;

  }
  
  .addProductItem {
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .addProductItem > label {
    color: gray;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .addProductItem > input {
    padding: 10px;
    border: 0.01rem solid gray;
    border-radius: 1rem;
 

  }
  
  .addProductItem > select {
    padding: 10px;
    border: 0.01rem solid gray;
    border-radius: 1rem;

  }
  
  .addProductButton {
    margin-top: 10px;
    padding: 7px 10px;
    border: none;
    border-radius: 10px;
    background-color: darkblue;
    color: white;
    font-weight: 600;
    cursor: pointer;
    margin-bottom:2rem;
  }
  

  .loader{
    display: grid;
    align-items: center;
    justify-items: center;
  }


  .jew{

    height:20px;
    width: 10px;


  }